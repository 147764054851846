<template>
  <div class="dtlist" style="height:calc(100% - 68px)">
    <ul style="height:100%;overflow-y:auto;">
        <li v-for="item in List" v-bind:key="item.id">
       
                <a-row type="flex" class="row-flex" justify="start" align="center" style="">
                    <!--<a-col flex="50px" class="text-over_hidden">
                        09:45
                    </a-col>-->
                    <a-col flex="56px" style="margin-left:10px;">
                        <img class="thumb-avt" :src="item.UHeadImg" />
                    </a-col>
                    <a-col flex="auto" style="flex:1;">
                        <a-col :span="24">
                            <a-row type="flex" justify="start">
                                <a-col class="row-flex-title user-name" :title="item.Name">
                                    {{item.Name}}
                                </a-col>
                             
                                <a-col class="row-flex-title">
                                    <span class="tag" style="background:#2281f8;color:#fff;font-size:12px;padding:1px 2px;border-radius:4px;">{{item.TargetCategoryName}}</span>
                                </a-col>
                            </a-row>
                        </a-col>
                        <a-col :span="24">
                            <a-row type="flex" justify="start">
                                    <a-col class="row-flex-title"> 
                                    <span style="font-size:14px;">介绍人:{{item.IntroducerName}} </span>
                                    <p style="font-size:14px;">{{item.InputTime}}{{item.Summary}}</p>
                                </a-col>
                            </a-row>
                        </a-col>
                    </a-col>
                </a-row>
          
            <!--<div class="thumb-avt-cont"></div>
                 <div class="dtlist-r"></div>-->
        </li>
    </ul>
  </div>
</template>

<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    export default {
        name: "Ranking",
        data() {
            return {
                List: [],
                BeginDate: util.formatDate(new Date()),
                EndDate: util.formatDate(new Date()),
            };
        },
        props: {//组件属性
        },
        methods: {//方法
            loadData() {
                var self = this;
                var op = {
                    url: "/OperationsModule/Operations2/GetDynamicLogList",
                    data: {
                        BeginDate: self.BeginDate,
                        EndDate: self.EndDate,
                    },
                    OnSuccess: function (res) {
                        res.data.forEach(e => {
                            e.InputTime = TimeStamptoDateTime(e.InputTime)
                        });
                        console.log(res.data.slice(0,8))
                        self.List = res.data.slice(0,8);
                    }
                };
                http.Post(op);
            },
            SetDate(BeginDate, EndDate) {
                console.log(BeginDate, EndDate)
                var self = this;
                self.BeginDate = BeginDate;
                self.EndDate = EndDate;
                self.loadData();
            },
        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
            var self = this;
            self.loadData();
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };

    function TimeStamptoDateTime(timeSpan) {
        var dateTime = new Date(parseInt(timeSpan.substring(6, timeSpan.length - 2)));
        var year = dateTime.getFullYear();
        var month = change(dateTime.getMonth() + 1);
        var day = change(dateTime.getDate());
        var hour = change(dateTime.getHours());
        var minute = change(dateTime.getMinutes());
        var second = change(dateTime.getSeconds());
        function change(t) {
            if (t < 10) {
                return "0" + t;
            } else {
                return t;
            }
        }
        var time = month + '-' + day + ' ' +  hour + ':' + minute;
        console.log(year); console.log(second);
        //console.log(second); console.log(hour); console.log(minute);
        return time;
      
    }
</script>

<style  scoped>
.thumb-avt{width: 46px;height:46px;border-radius: 50%;}
    .dtlist ul{ }
    .dtlist ul > li {
        display: flex;
        justify-content: flex-start;
        padding:0 5px 14px 10px;
        position: relative;
        font-size: 0.82rem;
        align-items: center;
    }
.thumb-avt-cont{flex-basis:44px;flex-shrink:0;text-align:center;margin-right:10px;}
.dtlist-r{flex:1;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;}
.dtlist-r span{margin:0 10px;}
/*.dtlist ul>li::before{position: absolute;border-left:1px dashed #e5e5e5;height:60px;width:1px;left:21px;top:0; content: '';}*/
    
    .dtlist ul > li:first-child {
        padding-top: 0;
    }
    .dtlist ul > li > a {
        color: #888;font-size:12px;display:block;width:100%;
    }
    .row-flex {
        width: 100%;
        overflow: hidden;
        align-items: center;
    }

    .row-flex-title{max-width:300px; overflow:hidden;color:#444;font-size:16px;margin-right:10px;text-overflow:ellipsis;white-space:nowrap;}
.user-name{max-width:120px; overflow:hidden;color:#444;font-size:16px;margin-right:10px;text-overflow:ellipsis;white-space:nowrap;}
    .text-over_hidden{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}
    .row-flex_text {
        margin-top: 4px;
        color: #c5c5c5
    }

    ::-webkit-scrollbar { /*滚动条整体样式*/
        width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
        height: 1px;
    } 

::-webkit-scrollbar-thumb {/*滚动条里面小方块*/

        border-radius: 10px;

         -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);

        background: #c5c5c5;

    }

::-webkit-scrollbar-track {/*滚动条里面轨道*/

        -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);

        border-radius: 10px;

        background: #f2f2f9;

}
</style>>
 