<template>
    <div class="wall-bdc-bot">
        <h3 class="customer-division-title">访问分布图</h3>
        <div class="customer-division">
            <div id="customerDivision">
                <div style="height:280px;padding-top:20px;">
                    <baidu-map class="bm-view"
                               ak="E8f3c743837925ce120eb93417f478db"
                               :center="center"
                               :zoom="zoom"
                               :scroll-wheel-zoom="true"
                               @ready="handler"
                               v-if="Spot">
                        <bm-marker v-for="item in coorddata"
                                   v-bind:key="item.ID"
                                   :position="{lng: item.lng, lat: item.lat }"
                                   :title="item.label"></bm-marker>
                    </baidu-map>
                    <!--<baidu-map class="bm-view"
                               ak="E8f3c743837925ce120eb93417f478db"
                               :center="center"
                               :zoom="zoom"
                               :scroll-wheel-zoom="true"
                               @ready="handler"
                               v-if="Spot==false">
                        <bml-heatmap :data="coorddata" :max="100" :radius="20"></bml-heatmap>
                    </baidu-map>-->
                </div>
            </div>
        </div>
        <!--客户分布图-->
    </div>
</template>
<style>
    .sample {
        width: 120px;
        height: 40px;
        line-height: 40px;
        background: rgb(255, 255, 255);
        overflow: hidden;
        box-shadow: 0 0 5px #000;
        color: #f00;
        text-align: center;
        padding: 10px;
        position: absolute;
    }

        .sample.active {
            background: rgb(255, 255, 255);
            color: #f00;
        }

    .bm-view {
        width: 100%;
        height: 100%;
    }
</style>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    import BaiduMap from "vue-baidu-map/components/map/Map.vue";
    import {BmMarker} from "vue-baidu-map";
    //import { BmlHeatmap } from "vue-baidu-map";
    export default {
        name: "Newusermap",
        data() {
            return {
                active: true,
                center: { lng: 0, lat: 0 },
                markPoint: { lng: 114.383176, lat: 36.062233 },
                zoom: 10,
                count1: 0,
                BeginDate: util.formatDate(new Date()),
                EndDate: util.formatDate(new Date()),
                coorddata: [],
                start: false,
                handlerdata: [],
                Spot: true,
                //MapInterval: Object,
                mapdate: []
            };
        },
        props: {
            //组件属性
        },
        methods: {
            //方法
            handler({ BMap, map }) {
                map.centerAddZoom = console.log(BMap, map);
                let mapStyle = { style: "" };
                map.setMapStyle(mapStyle);
            },
            draw({ el, BMap, map }) {
                const pixel = map.pointToOverlayPixel(new BMap.Point(116.404, 39.915));
                el.style.left = pixel.x - 60 + "px";
                el.style.top = pixel.y - 20 + "px";
            },

            getData: function () {
                var self = this;
                var op = {
                    url: "/OperationsModule/Operations2/GetAgentMapCoord",
                    data: {
                        BeginDate: self.BeginDate,
                        EndDate: self.EndDate
                    },
                    OnSuccess: function (data) {
                        console.log("data:" + data.data);

                        var center = data.data[0];
                        self.handlerdata.push.apply(self.handlerdata, data.data);//data.data数据追加到self.handlerdata

                        self.coorddata = self.handlerdata;
                        console.log("self.coorddataaaaaaa", self.coorddata);
                        console.log("self.length", data.data.length);
                        self.center.lng = center.lng;
                        self.center.lat = center.lat;
                        self.zoom = 13;
                        if (data.data.length > 500) {
                            self.Spot = false;
                        }
                    }
                };
                http.Post(op);
            },
            del_handlerdata() {
                var self = this;
                self.handlerdata = self.coorddata = [];
            },
            //GetMpData() {
            //    var self = this;
            //    var list = self.mapdate;
            //    var index = 0;
            //    self.BeginDate = list[index + i][0];
            //    self.EndDate = list[index + i][1];
            //    self.getData();
            //    list.length = list.length + 1;
            //    let count1 = list.length / 2;
            //    list.length = list.length - 1;
            //    for (var i = 0; i < count1; i++) {
            //        setTimeout(function () {
            //            if (i == count1 - 1) {
            //                self.BeginDate = list[index + i][0];
            //                self.EndDate = list[index + i][1];
            //            } else {
            //                self.BeginDate = list[index + i][0];
            //                self.EndDate = list[index + i + 1][1];
            //            }
            //            index++;
            //            self.getData();
            //        }, 2000);
            //    }
            //},
            SetDate(BeginDate, EndDate) {
                var self = this;
                self.start = true;
                // var timeArry = [];
                // self.coorddata = [];
                // self.mapdate = [];
                // let month_num = util.getDateNum(BeginDate, EndDate, "month") - 1;
                // console.log("加载数据之前点集合：" + self.coorddata);
                // let time = EndDate;
                // let _endtime;
                // if (month_num > 1) {
                //   for (var i = 0; i < month_num; i++) {
                //     _endtime = util.handleMonths(BeginDate, 0).split("&")[0]; //结束时间
                //     if (i != month_num - 1) {
                //       self.BeginDate = BeginDate;
                //       self.EndDate = _endtime;
                //       BeginDate = util.handleMonths(BeginDate, 0).split("&")[1];
                //     } else {
                //       self.BeginDate = util.handleMonths(BeginDate, 1).split("&")[1];
                //       self.EndDate = time;
                //       //self.getData();
                //     }
                //     timeArry = [self.BeginDate, self.EndDate];
                //     self.mapdate.push(timeArry);
                //   }
                // } else {
                //   self.BeginDate = BeginDate;
                //   self.EndDate = EndDate;
                //   self.getData();
                // }
                // self.GetMpData();
                self.BeginDate = BeginDate;
                self.EndDate = EndDate;
                self.getData();
            }
        },
        computed: {
            //计算属性
        },
        watch: {
            //侦听属性
        },
        components: {
            //组件
            BaiduMap,
            BmMarker,
            //BmlHeatmap
            //BmLabel
        },
        beforeCreate: function () {
            //生命周期，创建前
        },
        created: function () {
            //生命周期，创建完成
        },
        beforeMount: function () {
            //生命周期，组装前
        },
        mounted: function () {
            //生命周期，组装完成
            var self = this;
            self.getData();
        },
        beforeUpdate: function () {
            //生命周期，data更新前
        },
        updated: function () {
            // //生命周期，data更新完成
        },
        beforeDestroy: function () {
            //生命周期，销毁前
        },
        destroyed: function () {
            //生命周期，销毁完成
        }
    };
</script>