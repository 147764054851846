<template>
    <div>
        <div class="wall-bdl-top">

            <!--<a @click="NewMaterial_List()">更多</a>
            <h3 class="wall-article-title">热门内容</h3>-->
            <div class="ranking-tlists">
                <a-row type="flex" class="ranking-list ranking-list2" justify="start" v-for="(item,index) in Material.slice(0,5)" v-bind:key="item.MaterialID">
                    <a-col flex="40px" class="textal_c ranking-tnumbe">{{index+1}}</a-col>
                    <a-col flex="74px" class="ranking-timg"><img :src="Getsrc(item)" /> </a-col>
                    <a-col flex="auto" class="ranking-ttitle">
                        <div class="ranking-list2-title" @click="ShowDetail(item)">{{item.Name}}</div>
                        <div class="ranking-list2-text">
                            <a-space size="large">
                                <div @click="ReadCount(item)">访问：{{item.ReadCount}}</div>
                                <div @click="PullNewCount(item)">拉新：{{item.NewReadCount}}</div>
                            </a-space>

                        </div>
                    </a-col>

                </a-row>
            </div>
            <!--<div class="wall-article" v-for="item in Material.slice(0,5)" v-bind:key="item.id">
                <div class="wall-article-list">
                    <div class="wall-article-list-title" @click="ShowDetail(item)">{{item.Material.Name}}</div>
                    <div class="wall-article-list-text" @click="ReadCount(item)">阅读：{{item.ReadCount }}</div>
                    <div class="wall-article-list-text" @click="PullNewCount(item)">拉新：{{item.PullNewCount }}</div>
                </div>
            </div>-->
        </div>
        <div v-if="DetailVisible">
            <Detail :closeDetail="closeDetail" :MaterialType="MaterialType" :ID="ID" :PushID="PushID" :IDType=0 :disabled="disabled"></Detail>
            <!--<Detail :closeDetail="closeDetail" :MaterialType="MaterialType" :ID="ID" :QueueID=0 :disabled="disabled"></Detail>-->
        </div>
    </div>
</template>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    import Detail from '@/Views/MaterialModule/M2Material/Detail';
    export default {
        name: "Material",
        data() {
            return {
                Material: [],
                DetailVisible: false,
                MaterialType: -1,
                ID: "",
                disabled: false,

                BeginDate: util.formatDate(new Date()),
                EndDate: util.formatDate(new Date()),
            };
        },
        props: {//组件属性

        },
        methods: {//方法
            loadData() {
                var self = this;
                var op = {
                    url: "/OperationsModule/Operations2/GetMaterialReadCountList",
                    data: {
                        BeginDate: self.BeginDate,
                        EndDate: self.EndDate
                    },
                    OnSuccess: function (res) {

                        self.Material = res.data;
                        console.log("data", res.data)
                        console.log("Material", self.Material)
                    }
                };
                http.Post(op);
            },
            SetDate(BeginDate, EndDate) {
                console.log(BeginDate, EndDate)
                var self = this;
                self.BeginDate = BeginDate;
                self.EndDate = EndDate;
                self.loadData();
            },
            NewMaterial_List() {
                this.$router.push({
                    name: "NewMaterial_List"
                })
            },
            ShowDetail(e) {
                var self = this;
                self.MaterialType = e.MaterialType;
                self.ID = e.MaterialID;
                self.disabled = e.PushState == 1 ? true : false;
                self.DetailVisible = true;
            },
            ReadCount: function (data) {
                var self = this;
                self.$router.push({
                    name: "Material_ReadList", query: {
                        ID: data.MaterialID,
                        IsNewUser: 0
                    }
                })
            },
            PullNewCount: function (data) {
                var self = this;
                self.$router.push({
                    name: "Material_ReadList", query: {
                        ID: data.MaterialID,
                        IsNewUser: 1
                    }
                })
            },
            closeDetail(e) {
                var self = this;
                if (e) {
                    // self.loadData();
                }
                self.DetailVisible = false;
            },
            Getsrc: function (item) {
                var rt = item.Logo;// item.ImgTopic == null ? item.Logo : item.ImgTopic;
                if (rt == null) {
                    rt = "#"
                } else {
                    if (rt.indexOf('upload2') < 0) {
                        rt = rt.replace("upload", "upload2");
                    }
                }
                return rt;
            },
        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
            Detail
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成

        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>