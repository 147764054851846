<template>
    <div>
        <div class="wall-data">

            <div><span @click="achievement()">{{VistingCount}}</span><p>访问</p></div>
            <div><span @click="AgentGuestLogList(0)">{{d_pullnew}}</span><p>拉新</p></div>
            <div><span @click="AgentGuestLogList(1)">{{d_intention}}</span><p>意向</p></div>
            <div><span @click="AgentGuestLogList(2)">{{d_consult}}</span><p>咨询</p></div>
            <div><span @click="OrderList(1)">{{d_deal}}</span><p>成交</p></div>

        </div>
    </div>
</template>
<style>
    .wall-data {
        height: 90px;
        font-size: 40px;
        grid-template-columns: 20% 20% 20% 20% 20%
    }

        .wall-data > div {
            font-size: 40px
        }
</style>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    export default {
        name: "Newachievement",
        data() {
            return {
                VistingCount: 0,
                d_pullnew: 0,
                d_intention: 0,
                d_consult: 0,
                d_deal: 0,
                BeginDate: new Date(),
                EndDate: new Date(),
            };
        },
        props: {//组件属性
        },
        methods: {//方法
            getData: function () {
                var self = this;
                self.d_pullnew =
                    self.d_intention =
                    self.d_consult =
                    self.VistingCount =
                    self.d_deal = "-";
                self.VistingCount = 0;
                var op = {
                    url: "/OperationsModule/Operations2/GetAgentGuestLogInfoCount",
                    data: {
                        BeginDate: self.BeginDate,
                        EndDate: self.EndDate,
                    },
                    OnSuccess: function (hdata) {
                        var data = hdata.data;
                        self.anmshow(data.NewCount, 8, "d_pullnew");
                        self.anmshow(data.VisitCount, 8, "d_intention");
                        self.anmshow(data.IntentionCount, 8, "d_consult");
                        self.anmshow(data.DealCount, 8, "d_deal");
                    }
                };
                http.Post(op);
            },
            //refreshVisit:function(){
            //    var self = this;
            //    var op = {
            //        url: "/OperationsModule/Operations2/GetQRVisitReportItem1",
            //        data: {
            //            BeginDate: self.BeginDate,
            //            EndDate: self.EndDate,
            //        },
            //        OnSuccess: function (hdata) {
            //            console.log(hdata.data);
            //            self.VistingCount = hdata.data;
            //            // var data = hdata.data;
            //            // for(var i=0;i<data.length;i++)
            //            // {
            //            //     self.VistingCount+= data[i].QRcodeRecordCount
            //            // }
            //        }
            //    };
            //    http.Post(op);

            //},
             refreshVisit: function (vcount) {
                 var self = this;
                 self.anmshow(vcount, 8, "VistingCount");
             },
            anmshow: function (v, l, o) {
                var self = this;
                if (!v) {
                    self[o] = v;
                    return;
                }
                if (v < l)
                    l = v;
                var c = parseInt(v / l) || 1;
                (function loop(no, lc, sum, fd) { //显示数据,循环次数,当前累计值,字段
                    sum += lc;
                    sum < no && (setTimeout(loop, 100, no, lc, sum, fd), self[o] = sum, 1) || (self[o] = no, 1);
                })(v, c, 0, o);
            },
            SetDate(BeginDate, EndDate) {
                var self = this;
                self.BeginDate = BeginDate;
                self.EndDate = EndDate;
                self.getData();
                //self.refreshVisit();
            },
            achievement() {
                var self = this;
                self.$router.push({
                    name: "Operations_QRcodeRecordList",
                    query: {
                        BeginDate: util.TimeStamptoDateTime("/Date(" + new Date(self.BeginDate).getTime() + ")/", 'yyyy-MM-dd'),
                        EndDate: util.TimeStamptoDateTime("/Date(" + new Date(self.EndDate).getTime() + ")/", 'yyyy-MM-dd'),
                    }
                })
            },
            AgentGuestLogList(e) {
                var self = this;
                self.$router.push({
                    name: "Operations_AgentGuestLogList",
                    query: {
                        Category: e,
                        BeginDate: util.TimeStamptoDateTime("/Date(" + new Date(self.BeginDate).getTime() + ")/", 'yyyy-MM-dd'),
                        EndDate: util.TimeStamptoDateTime("/Date(" + new Date(self.EndDate).getTime() + ")/", 'yyyy-MM-dd'),
                    }
                })
            },
            OrderList(e) {
                var self = this;
                self.$router.push({
                    name: "Order_OrderList",
                    query: {
                        PayState:e,
                        BeginDate: util.TimeStamptoDateTime("/Date(" + new Date(self.BeginDate).getTime() + ")/", 'yyyy-MM-dd'),
                        EndDate: util.TimeStamptoDateTime("/Date(" + new Date(self.EndDate).getTime() + ")/", 'yyyy-MM-dd'),
                    }
                })
            },
        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
            //this.getData();
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>