<template>
  <div id="index">
    <a-layout>
      <a-layout-header
        style="height: 80px; background-color: #f0f2f5; position: relative"
      >
        <!-- <div class="wall-head-time">{{ date }}</div> -->
        <h2 class="wall-head-title">{{ CustomerName_Show }}作战大屏</h2>
        <!--<a @click="handleFullScreen" class="full">{{fullscreen?'取消全屏':'全屏'}}</a>-->
      </a-layout-header>
      <a-layout-content style="padding: 0 10px">
        <div class="index-float">
          <a-radio-group
            default-value="0"
            button-style="solid"
            style="margin-right: 12px"
            size="small"
            @change="radioChange"
          >
            <a-radio-button value="0"> 今天 </a-radio-button>
            <a-radio-button value="1"> 昨天 </a-radio-button>
            <a-radio-button value="2"> 最近7天 </a-radio-button>
            <a-radio-button value="3"> 最近30天 </a-radio-button>
          </a-radio-group>

          <a-range-picker
            size="small"
            style="width: 220px"
            @change="handleChange"
            :value="Time"
          >
            <template slot="dateRender" slot-scope="current">
              <div class="ant-calendar-date" :style="getCurrentStyle(current)">
                {{ current.date() }}
              </div>
            </template>
          </a-range-picker>
        </div>

        <Newachievement ref="Newachievement" />

        <!-- <div class="wall-date">
                  <div class="wall-datel">
                    <a-radio-group defaultValue="0" @change="radioChange">
                      <a-radio-button value="0">今天</a-radio-button>
                      <a-radio-button value="1">昨天</a-radio-button>
                      <a-radio-button value="2">近七天</a-radio-button>
                      <a-radio-button value="3">近30天</a-radio-button>
                      <a-radio-button value="4">上半年</a-radio-button>
                    </a-radio-group>
                  </div>
                  <div class="wall-dater">
                    <a-range-picker :value="Time" @change="handleChange" />
                  </div>
                </div> -->

        <div class="wall-bd">
          <div class="wall-bdl">
            <h3 class="wall-bdl-title" style="padding: 10px 0">推广动态</h3>
            <List ref="List" />
          </div>
          <div class="wall-bdc">
            <Recruit ref="Recruit" />
            <Newusermap ref="Newusermap" />

            <!-- <div class="wall-bdc-top">1</div>
                        <div class="wall-bdc-bot">1</div> -->
          </div>
          <Ranking ref="Ranking" />
        </div>
      </a-layout-content>
    </a-layout>
    <!--<div class="wall-head">


        </div>
        <div class="wall-cont">

        </div>-->
  </div>
</template>
<script>
import "@/assets/css/wallchart.css";
import Account from "@/Plugin/Account.js";
import Newachievement from "./components/Newachievement";
import List from "./components/List";
//import NewscaleChart from "./components/NewscaleChart";
import Recruit from "./components/Recruit";
import Newusermap from "./components/Newusermap";
import Ranking from "./components/Ranking";
import util from "@/Plugin/util.js";
import moment from "moment";

export default {
  name: "",
  data() {
    return {
      date: this.GetDate(),
      Time: [],
      BeginDate: new Date(),
      EndDate: new Date(),
      fullscreen: false,
      CustomerName_Show: "",
      start:0,
      MapInterval:Object
    };
  },
  props: {
    //组件属性
  },
  methods: {
    //方法
    GetDate() {
      return util.formatDateTime(new Date());
    },
    getCurrentStyle(current, today) {
      console.log(today);
      const style = {};
      if (current.date() === 1) {
        style.border = "1px solid #1890ff";
        style.borderRadius = "50%";
      }
      return style;
    },
    handleSizeChange(e) {
      this.size = e.target.value;
    },

    handleFullScreen() {
      let element = document.documentElement;
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      this.fullscreen = !this.fullscreen;
    },
    handleChange(value) {
      var self = this;
      self.start=1;
      if (value.length == 0) {
        self.BeginDate = util.formatDate(new Date());
        self.EndDate = util.formatDate(new Date());
        value = [
          moment(new Date(), "YYYY-MM-DD"),
          moment(new Date(), "YYYY-MM-DD"),
        ];
        self.$message.error("必须选择日期", 1.5, function () {});
      } else {
        self.BeginDate = util.formatDate(value[0]._d);
        self.EndDate = util.formatDate(value[1]._d);
      }
      
      self.Time = value;
      self.$refs.List.SetDate(self.BeginDate, self.EndDate);
      self.$refs.Ranking.SetDate(self.BeginDate, self.EndDate);
      self.$refs.Newachievement.SetDate(self.BeginDate, self.EndDate);
      self.$refs.Recruit.SetDate(self.BeginDate,self.EndDate,self.$refs.Newachievement.refreshVisit);
      //self.$refs.Newusermap.del_handlerdata();
      self.Mapload(self.BeginDate,self.EndDate);
    },
      Mapload: function (BeginDate, EndDate) {
          var self = this;
          if (BeginDate != EndDate) {
              self.MapInterval = setInterval(function () {
                  var Endtime = util.ReturnTenDay(BeginDate, 10);
                  if (BeginDate != EndDate && BeginDate < EndDate) {
                      self.$refs.Newusermap.SetDate(BeginDate, Endtime, self.start);
                      BeginDate = Endtime;
                  }
                  else {
                      clearInterval(self.MapInterval);
                      self.$refs.Newusermap.SetDate(BeginDate, EndDate, self.start);
                  }
              }, 1000);
          }
          else {
              self.$refs.Newusermap.SetDate(BeginDate, EndDate, self.start);
          }

          //   let time = EndDate;
          //   let _endtime;
          //   if (month_num > 1) {
          //     for (var i = 0; i < month_num; i++) {
          //       _endtime = util.handleMonths(BeginDate, 0).split("&")[0]; //结束时间
          //       if (i != month_num - 1) {
          //         self.BeginDate = BeginDate;
          //         self.EndDate = _endtime;
          //         BeginDate = util.handleMonths(BeginDate, 0).split("&")[1];
          //       }else {
          //         self.BeginDate = util.handleMonths(BeginDate, 1).split("&")[1];
          //         self.EndDate = time;
          //       }
          //       self.$refs.Newusermap.SetDate(self.BeginDate, self.EndDate);
          //     }
          //   }
      },
    radioChange: function (item) {
      var self = this;
      var Time = "";
      switch (item.target.value) {
        case "0":
          Time = [
            moment(new Date(), "YYYY-MM-DD"),
            moment(new Date(), "YYYY-MM-DD"),
          ];
          break;
        case "1":
          Time = [
            moment(
              new Date(new Date().setDate(new Date().getDate() - 1)),
              "YYYY-MM-DD"
            ),
            moment(
              new Date(new Date().setDate(new Date().getDate() - 1)),
              "YYYY-MM-DD"
            ),
          ];
          break;
        case "2":
          Time = [
            moment(
              new Date(new Date().setDate(new Date().getDate() - 7)),
              "YYYY-MM-DD"
            ),
            moment(new Date(), "YYYY-MM-DD"),
          ];
          break;
        case "3":
          Time = [
            moment(
              new Date(new Date().setDate(new Date().getDate() - 30)),
              "YYYY-MM-DD"
            ),
            moment(new Date(), "YYYY-MM-DD"),
          ];
          break;
        case "4":
          var year = new Date().getFullYear();
          Time = [
            moment(new Date(moment(year + "-01-01")), "YYYY-MM-DD"),
            moment(new Date(moment(year + "-06-30")), "YYYY-MM-DD"),
          ];
          break;
      }
      self.handleChange(Time);
    },
  },
  computed: {
    //计算属性
  },
  watch: {
    //侦听属性
  },
  components: {
    //组件
    Newachievement,
    List,
    //NewscaleChart,
    Recruit,
    Newusermap,
    Ranking,
  },
  beforeCreate: function () {
    //生命周期，创建前
    var self = this;
    Account.GetCurrentUserInfo(function (CurrentUserInfo) {
      self.CustomerName_Show = CurrentUserInfo.CustomerName;
      self.SoftVersion = CurrentUserInfo.SoftVersion;
    });
  },
  created: function () {
    //生命周期，创建完成
  },
  beforeMount: function () {
    //生命周期，组装前
  },
  mounted: function () {
    //生命周期，组装完成

    var self = this;
    Account.GetCurrentUserInfo(function (CurrentUserInfo) {
      self.CustomerName_Show = CurrentUserInfo.CustomerName;
      self.SoftVersion = CurrentUserInfo.SoftVersion;
    });
    this.timer = setInterval(() => {
      self.date = self.GetDate(); // 修改日期数据
    }, 1000);
    self.Time = [
      moment(new Date(), "YYYY-MM-DD"),
      moment(new Date(), "YYYY-MM-DD"),
    ];
    self.BeginDate = util.formatDate(new Date());
    self.EndDate = util.formatDate(new Date());
    self.$refs.Newachievement.SetDate(self.BeginDate, self.EndDate);
    self.$refs.Newusermap.SetDate(self.BeginDate, self.EndDate);
    self.$refs.Recruit.SetDate(
      self.BeginDate,
      self.EndDate,
      self.$refs.Newachievement.refreshVisit
    );
  },
  beforeUpdate: function () {
    //生命周期，data更新前
  },
  updated: function () {
    //生命周期，data更新完成
  },
  beforeDestroy: function () {
    //生命周期，销毁前
  },
  destroyed: function () {
    //生命周期，销毁完成
  },
};
</script>
<style scoped>
.dtlist > ul > li {
  display: flex;
  justify-content: space-around;
}

.full {
  position: absolute;
  right: 12px;
  top: 0px;
  height: 50px;
  line-height: 50px;
  color: #888;
}

.index-float {
  padding: 0 12px;
  text-align: right;
  margin-bottom: 12px;
}

.wall-bdl-title {
  margin-bottom: 14px;
  text-align: center;
  font-weight: 700;
}

@media screen and (min-width: 1650px) {
  .index-float {
    position: absolute;
    right: 0;
    top: 24px;
  }
}
</style>